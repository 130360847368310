<template>
  <MainWrapperStyled :isLoading="$apollo.loading">
    <HeaderMolecule
      :title="'Create Extension'"
      :showBackButton="true"
      :buttonText="'Publish Extension'"
      class="header"
      :disabledAction="disablePublishButton"
      :goBackRoute="goBackRoute"
      :disableText="disableText"
      @action="publishExtension($event)"
    />
    <TalpaLoaderWrapper v-if="$apollo.loading" class="loader" />
    <template v-else>
      <GeneralInformationOrganism :myExtension="myExtension" />
      <ListAssetDimensionsOrganism />
      <ListSelectedAssetsOrganism :myExtension="myExtension" />
    </template>
  </MainWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaLoaderWrapper } from '@common/components'
import { FlashMessages } from '@common/singletons'

import HeaderMolecule from './HeaderMolecule.vue'
import GeneralInformationOrganism from './GeneralInformationOrganism.vue'
import ListAssetDimensionsOrganism from './ListAssetDimensionsOrganism.vue'
import ListSelectedAssetsOrganism from './ListSelectedAssetsOrganism.vue'

import MY_EXTENSION_QUERY from '#/graphql/extensionBuilder/myExtension.gql'
import PUBLISH_EXTENSION_MUTATION from '#/graphql/extensionBuilder/publishExtension.gql'

const MainWrapperStyled = styled('div')`
  position: relative;
  min-height: calc(100% - 1rem);
  display: grid;
  padding: 0.5rem;
  grid-gap: 0.5rem;
  > .header {
    grid-area: header;
  }
  > .loader {
    grid-area: loader;
  }
  ${({ isLoading }) =>
    isLoading &&
    `grid-template-areas:
      "header"
      "loader"; grid-template-columns: 1fr; grid-template-rows: 4rem 1fr;`};
  ${({ isLoading }) =>
    !isLoading &&
    ` grid-template-areas:
      'header'
      'information'
      'machines'
      'assetdimensions';
    grid-template-columns: 1fr;
    grid-template-rows: 4rem 14rem 14rem;`}

  @media (min-width: 1200px) {
    ${({ isLoading }) =>
      !isLoading &&
      ` grid-template-areas:
      'header header'
      'information assetdimensions'
      'machines assetdimensions';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4rem 14rem 1fr;`};
  }
`
export default {
  components: {
    MainWrapperStyled,
    HeaderMolecule,
    GeneralInformationOrganism,
    ListAssetDimensionsOrganism,
    ListSelectedAssetsOrganism,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      myExtensions: [],
    }
  },
  computed: {
    goBackRoute() {
      return {
        name: 'ListExtensions',
      }
    },
    extensionId() {
      return this.$route.params.id
    },
    myExtension() {
      return this.myExtensions[0] ?? []
    },
    disablePublishButton() {
      return (
        !this.myExtension?.name ||
        this.myExtension?.assetIds.length === 0 ||
        this.myExtension?.assetDimensionDefinitions.length === 0 ||
        this.myExtension?.assetDimensionDefinitions.filter(e => e.metricId === null).length > 0 ||
        this.myExtension?.assetDimensionDefinitions.filter(e => e.aggregations === null).length > 0
      )
    },
    disableText() {
      const errorTexts = []
      if (!this.$apollo.loading) {
        if (!this.myExtension?.name) {
          errorTexts.push({ id: 'no_ext_name', text: 'Please provide a valid Extension Name' })
        }
        if (this.myExtension?.assetIds.length === 0) {
          errorTexts.push({ id: 'no_ext_assets', text: 'Please Add Valid Assets' })
        }
        if (this.myExtension?.assetDimensionDefinitions.length === 0) {
          errorTexts.push({ id: 'no_ext_adDef', text: 'Please Add Valid Asset Dimensions' })
        }
        if (this.myExtension?.assetDimensionDefinitions.filter(e => e.metricId === null).length > 0) {
          errorTexts.push({ id: 'no_ext_ad_metric', text: 'Please Add Valid Asset Dimension metric' })
        }
        if (this.myExtension?.assetDimensionDefinitions.filter(e => e.aggregations === null).length > 0) {
          errorTexts.push({ id: 'no_ext_ad_agg', text: 'Please Add Valid Asset Dimension aggregation' })
        }
      }
      return errorTexts
    },
  },
  methods: {
    async publishExtension() {
      try {
        const publishedExtension = await this.$apollo.mutate({
          mutation: PUBLISH_EXTENSION_MUTATION,
          variables: {
            id: this.extensionId,
          },
        })
        const extension = publishedExtension?.data?.publishExtension
        if (extension?.id) {
          FlashMessages.$emit('success', `The Extension ${extension.name} has been Published!`, {
            timeout: 3000,
          })
          this.$router.push({
            name: 'ListExtensions',
          })
        }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    myExtensions: {
      query: MY_EXTENSION_QUERY,
      variables() {
        return {
          where: {
            id: {
              equals: this.extensionId,
            },
          },
        }
      },
      skip() {
        return !this.extensionId
      },
      fetchPolicy: 'network-only',
    },
  },
}
</script>
