var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainWrapperStyled', {
    attrs: {
      "isLoading": _vm.$apollo.loading
    }
  }, [_c('HeaderMolecule', {
    staticClass: "header",
    attrs: {
      "title": 'Create Extension',
      "showBackButton": true,
      "buttonText": 'Publish Extension',
      "disabledAction": _vm.disablePublishButton,
      "goBackRoute": _vm.goBackRoute,
      "disableText": _vm.disableText
    },
    on: {
      "action": function action($event) {
        return _vm.publishExtension($event);
      }
    }
  }), _vm.$apollo.loading ? _c('TalpaLoaderWrapper', {
    staticClass: "loader"
  }) : [_c('GeneralInformationOrganism', {
    attrs: {
      "myExtension": _vm.myExtension
    }
  }), _c('ListAssetDimensionsOrganism'), _c('ListSelectedAssetsOrganism', {
    attrs: {
      "myExtension": _vm.myExtension
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }