var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GeneralInformationWrapperStyled', [_c('HeaderMolecule', {
    attrs: {
      "title": 'General Information',
      "buttonText": 'Update Info',
      "disabledAction": _vm.disableAction
    },
    on: {
      "action": function action($event) {
        return _vm.updateExtension($event);
      }
    }
  }), _c('main', [_c('div', {
    staticClass: "row"
  }, [_c('ValidatableFieldMolecule', {
    staticClass: "name",
    attrs: {
      "validation": _vm.$v.name
    }
  }, [_c('EditableFieldMolecule', {
    attrs: {
      "label": _vm.$tc('name', 1),
      "value": _vm.$v.name.$model,
      "focus": true
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.$v.name, "$model", $event);
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "description"
  }, [_c('label', [_vm._v(_vm._s(_vm.$tc('description', 1)))]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.description,
      expression: "description"
    }],
    domProps: {
      "value": _vm.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.description = $event.target.value;
      }
    }
  })])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }