<template>
  <CircleBadgeStyled :marginLeft="marginLeft" :isSelected="isSelected" :size="size" :totalRowCount="totalRowCount">
    {{ label }} <span v-if="totalRowCount"> /{{ totalRowCount }} </span>
  </CircleBadgeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

const CircleBadgeStyled = styled('div')`
  ${flexCenter}
  width: ${({ size, totalRowCount }) => (size === 'S' ? '20px' : totalRowCount ? '65px' : '28px')};
  height: ${({ size }) => (size === 'S' ? '20px' : '28px')};
  font-size: 10px;
  font-weight: bold;
  border-radius: ${({ totalRowCount }) => (totalRowCount ? '20px' : '50%')};
  color: ${p => (p.isSelected ? p.theme.colors.white : p.theme.colors.primary)};
  background: ${p => p.theme.colors.atomic.circleIconBG};
  ${props => (props.marginLeft ? 'margin-left: ' + props.marginLeft + ';' : '')}
`

export default {
  inject: ['theme'],
  props: {
    label: {
      required: true,
    },
    marginLeft: {
      type: String,
    },
    isSelected: {
      type: Boolean,
      default: false,
      required: false,
    },
    size: {
      type: String,
      default: 'M',
      required: false,
    },
    totalRowCount: {
      type: Number,
      required: false,
    },
  },
  components: {
    CircleBadgeStyled,
  },
}
</script>
