<template>
  <AssetMainStyled>
    <TalpaLoaderWrapper v-if="isLoading" class="loader" />
    <template v-else>
      <ExtensionTableMolecule
        :columns="columns"
        :tableData="tableData"
        :title="'Selected Assets'"
        :buttonText="'Add/Modify Assets'"
        :to="to"
        :mode="'add'"
        :maxHeight="417"
        :sort-option="sortOption"
        :isLoading="isLoading"
        :enableSearch="true"
        :searchQuery="searchQuery"
        @search="textSearch($event)"
        v-if="selectedAssets.length"
      />
      <NoDataMolecule
        v-else
        :to="to"
        :mode="'add'"
        :info="'You have not selected any Assets yet. Click the Add Assets button.'"
        :icon="'asset'"
        :buttonText="'Add Assets'"
        :title="'Selected Assets'"
      />
    </template>
  </AssetMainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import localesMixin from '@/mixins/locales'
import ExtensionTableMolecule from './ExtensionTableMolecule.vue'
import NoDataMolecule from './NoDataMolecule.vue'

import { MinusCircleIcon } from 'vue-feather-icons'
import { TalpaLoaderWrapper } from '@common/components'
import { FlashMessages } from '@common/singletons'
import { concat, orderBy, partition, get } from 'lodash'

import UPDATE_EXTENSION_MUTATION from '#/graphql/extensionBuilder/updateExtension.gql'

const AssetMainStyled = styled('div')`
  grid-area: machines;
  display: grid;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 0.5rem;
`

export default {
  inject: ['uiSettings'],
  mixins: [localesMixin],
  components: {
    AssetMainStyled,
    ExtensionTableMolecule,
    NoDataMolecule,
    TalpaLoaderWrapper,
  },
  props: {
    myExtension: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      sortOption: {
        multipleSort: false,
        sortChange: params => {
          this.sortChange(params)
        },
      },
      selectedAssets: this.myExtension?.assets ?? [],
    }
  },
  computed: {
    to() {
      return {
        name: 'AddAssets',
        params: {
          id: this.$route.params.id,
        },
      }
    },
    isLoading() {
      return this.$apollo.loading
    },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'name',
          title: this.$tc('name', 1),
          align: 'left',
          sortBy: 'asc',
          width: '40%',
        },
        { field: 'type', key: 'type', title: 'Type', align: 'left', sortBy: 'asc' },
        { field: 'oem', key: 'oem', title: 'OEM', align: 'left', sortBy: 'asc', width: '15%' },
        { field: 'model', key: 'model', title: 'Model', align: 'left', width: '15%', sortBy: 'asc' },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          width: '5%',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <span class="actions">
                <div on-click={() => this.confirmRemoveAsset(row)}>
                  <MinusCircleIcon size="1.3x" />
                </div>
              </span>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.selectedAssets.map(asset => {
        return {
          rowKey: asset?.id,
          name: asset?.name,
          type: asset?.type?.name,
          oem: asset?.oem?.alias,
          model: asset?.model?.name,
        }
      })
      const filteredTable = tableData.filter(item =>
        Object.values(item).some(value => String(value)?.toLowerCase().includes(this.searchQuery.toLowerCase())),
      )
      return filteredTable
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        message = this.searchQuery === '' ? this.$tc('messages.noData') : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
  },
  methods: {
    textSearch(query) {
      this.searchQuery = query
    },
    sortChange(params) {
      const sortKey = Object.keys(params).filter(key => params[key] !== '')
      const sortDirection = params[sortKey]
      const partitions = partition(this.selectedAssets, x => !!get(x, sortKey, null))
      this.selectedAssets = concat(orderBy(partitions[0], [sortKey], [sortDirection]), partitions[1])
    },
    confirmRemoveAsset(row) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'ExtensionAsset',
        instance: row,
        labelKey: 'name',
        onConfirm: this.updateExtensionWithAssets,
        onConfirmArgs: row,
      })
    },
    async updateExtensionWithAssets(row) {
      try {
        const extension = await this.$apollo.mutate({
          mutation: UPDATE_EXTENSION_MUTATION,
          variables: {
            data: {
              name: this.myExtension?.name,
              description: this.myExtension?.description,
              assetIds: this.myExtension?.assetIds.filter(id => id !== row.rowKey),
            },
            extensionId: this.$route.params.id,
          },
        })
        if (extension?.data?.updateExtension?.id) {
          this.selectedAssets = this.myExtension?.assets.filter(asset => asset.id !== row.rowKey)
          this.$root.$emit('closeOverlay')
          FlashMessages.$emit('success', `The Asset ${row.name} has been Removed!`, {
            timeout: 3000,
          })
        }
      } catch (err) {
        this.$root.$emit('closeOverlay')
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {},
}
</script>
