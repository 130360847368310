<template>
  <AssetDimensionsTableStyled>
    <TalpaLoaderWrapper v-if="isLoading" class="loader" />
    <template v-else>
      <ExtensionTableMolecule
        :columns="columns"
        :tableData="tableData"
        :title="'Asset Dimensions'"
        :buttonText="'Add Asset Dimensions'"
        :mode="'add'"
        :maxHeight="700"
        :isLoading="isLoading"
        :sort-option="sortOption"
        :disabledAction="!assetIds.length"
        :expand-option="expandOption"
        v-if="assetDimensionDefinitions.length && assetIds.length"
        @action="createAssetDimensionDefinition($event)"
      />
      <NoDataMolecule
        v-else
        :info="'You have not created Asset Dimensions yet. Click the Add Asset Dimensions button.'"
        :icon="'assetDimension'"
        :buttonText="'Add Asset Dimensions'"
        :title="'Asset Dimensions'"
        :disabled="!assetIds.length"
        :disabledInfoText="'Select Assets first to create Asset Dimensions!'"
        @action="createAssetDimensionDefinition($event)"
      />
    </template>
  </AssetDimensionsTableStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { TrashIcon } from 'vue-feather-icons'
import { concat, orderBy, partition, get } from 'lodash'

import localesMixin from '@/mixins/locales'
import { TalpaLoaderWrapper } from '@common/components'
import { FlashMessages } from '@common/singletons'
import NoDataMolecule from './NoDataMolecule.vue'
import ExtensionTableMolecule from './ExtensionTableMolecule.vue'

import MY_EXTENSION_QUERY from '#/graphql/extensionBuilder/myExtension.gql'
import CREATE_AD_DEFINITION_MUTATION from '#/graphql/extensionBuilder/createAssetDimensionDefinition.gql'
import DELETE_AD_DEFINITION_MUTATION from '#/graphql/extensionBuilder/deleteAssetDimensionDefinition.gql'

const AssetDimensionsTableStyled = styled('div')`
  grid-area: assetdimensions;
  display: grid;
  .link {
    cursor: pointer;
  }
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 0.5rem;
  .filters {
    display: flex;
    gap: 1rem;
    align-items: center;
    > span {
      display: flex;
      gap: 1rem;
      border: 1px solid ${({ theme }) => chroma(theme.colors.atomic.iconBG).alpha(0.3).css()};
      border-radius: 2rem;
      padding: 0.25rem;
    }
  }
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    gap: 1rem;
    color: ${p => p.theme.colors.primary};

    > div,
    button {
      padding: 0;
      cursor: pointer;
      > svg {
        color: ${p => p.theme.colors.primary};
      }
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  inject: ['uiSettings'],
  mixins: [localesMixin],
  components: {
    AssetDimensionsTableStyled,
    ExtensionTableMolecule,
    NoDataMolecule,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      myExtensions: [],
      sortOption: {
        multipleSort: false,
        sortChange: params => {
          this.sortChange(params)
        },
      },
      expandOption: {
        expandable: ({ row }) => {
          const filters = row.filters
          if (!filters.length) {
            return false
          }
        },
        render: ({ row }) => {
          const filters = row.filters
          return (
            <div class="filters">
              FILTERS:
              {filters.map((item, index) => (
                <span key={index}>
                  {item.field} {this.getFilterOperatorLabel(item.operator)} {item.values}
                </span>
              ))}
            </div>
          )
        },
      },
    }
  },
  computed: {
    myExtension() {
      return this.myExtensions[0]
    },
    extensionId() {
      return this.$route.params.id ?? null
    },
    iExtensionId() {
      return this.myExtension?.iExtensionId ?? null
    },
    assetIds() {
      return this.myExtension?.assetIds ?? []
    },
    assetDimensionDefinitions() {
      return orderBy(this.myExtension?.assetDimensionDefinitions, 'updatedAt', 'desc') ?? []
    },
    isLoading() {
      return this.$apollo.loading
    },

    columns() {
      const header = [
        {
          field: '',
          key: 'a',
          type: 'expand',
          title: '',
          width: 50,
          align: 'center',
        },
        {
          field: 'name',
          key: 'name',
          title: this.$tc('name', 2),
          align: 'left',
          sortBy: 'asc',
          renderBodyCell: ({ row, column }) => {
            const text = row[column.field]

            return (
              <a
                class="link"
                on-click={() =>
                  this.$router.push({ name: 'AddAssetDimension', params: { id: this.extensionId, assetDimensionId: row?.rowKey } })
                }
              >
                {{ text }}
              </a>
            )
          },
        },
        { field: 'metric', key: 'metric', title: 'Metric', align: 'left' },
        { field: 'aggregation', key: 'aggregation', title: 'Aggregation', align: 'left', width: 80 },
        {
          field: 'metricUnit',
          key: 'metricUnit',
          title: 'Metric Unit',
          width: 80,
          align: 'left',
        },
        {
          field: 'imperialUnit',
          key: 'imperialUnit',
          title: 'Imperial Unit',
          width: 80,
          align: 'left',
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Actions',
          width: '5%',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <span class="actions">
                <Button on-click={() => this.confirmDeleteExtension(row)}>
                  <TrashIcon size="1.3x" />
                </Button>
              </span>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.assetDimensionDefinitions.map(addef => {
        return {
          rowKey: addef.id,
          name: addef?.name,
          metric: addef?.metricId,
          aggregation: addef?.aggregation,
          imperialUnit: addef?.imperialUnit,
          metricUnit: addef?.metricUnit,
          filters: addef?.filters,
        }
      })
      return tableData
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        message = this.searchQuery === '' ? this.$tc('messages.noData') : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
  },
  methods: {
    getFilterOperatorLabel(operator) {
      const availableFilterOperators = [
        { id: 'EQ', label: '=' },
        { id: 'NEQ', label: '≠' },
        { id: 'GT', label: '>' },
        { id: 'GTE', label: '>=' },
        { id: 'LT', label: '<' },
        { id: 'LTE', label: '<=' },
        { id: 'IN', label: 'IN' },
        { id: 'BETWEEN', label: 'BETWEEN' },
      ]
      return availableFilterOperators.find(e => e.id === operator)?.label ?? operator
    },
    sortChange(params) {
      const sortKey = Object.keys(params).filter(key => params[key] !== '')
      const sortDirection = params[sortKey]
      const partitions = partition(this.assetDimensionDefinitions, x => !!get(x, sortKey, null))
      this.myExtension.assetDimensionDefinitions = concat(orderBy(partitions[0], [sortKey], [sortDirection]), partitions[1])
    },
    confirmDeleteExtension(row) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'ADDefinition',
        instance: row,
        labelKey: 'name',
        onConfirm: this.deleteAssetDimensionDefinition,
        onConfirmArgs: row,
      })
    },
    async deleteAssetDimensionDefinition(row) {
      const id = row.rowKey
      try {
        const deleteExtension = await this.$apollo.mutate({
          mutation: DELETE_AD_DEFINITION_MUTATION,
          variables: {
            extensionId: this.extensionId,
            assetDimensionDefinitionId: id,
          },
        })
        if (deleteExtension) {
          this.$root.$emit('closeOverlay')
          FlashMessages.$emit('success', `Successfully Removed the Extension ${row.name}`, {
            timeout: 3000,
          })

          this.$apollo.queries.myExtensions.refetch()
        }
      } catch (err) {
        this.$root.$emit('closeOverlay')
        FlashMessages.$emit('error', err)
      }
    },
    async createAssetDimensionDefinition() {
      try {
        const assetDimensionDefinition = await this.$apollo.mutate({
          mutation: CREATE_AD_DEFINITION_MUTATION,
          variables: {
            extensionId: this.extensionId,
            data: {
              name: 'New AssetDimension',
            },
          },
        })

        const definitionId = assetDimensionDefinition?.data?.createAssetDimensionDefinition?.id
        if (definitionId) {
          this.$router.push({
            name: 'AddAssetDimension',
            params: {
              id: this.extensionId,
              assetDimensionId: definitionId,
            },
          })
        }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    myExtensions: {
      query: MY_EXTENSION_QUERY,
      variables() {
        return {
          where: {
            id: {
              equals: this.$route.params.id,
            },
          },
        }
      },
      skip() {
        return !this.extensionId
      },
    },
  },
}
</script>
