var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetMainStyled', [_vm.isLoading ? _c('TalpaLoaderWrapper', {
    staticClass: "loader"
  }) : [_vm.selectedAssets.length ? _c('ExtensionTableMolecule', {
    attrs: {
      "columns": _vm.columns,
      "tableData": _vm.tableData,
      "title": 'Selected Assets',
      "buttonText": 'Add/Modify Assets',
      "to": _vm.to,
      "mode": 'add',
      "maxHeight": 417,
      "sort-option": _vm.sortOption,
      "isLoading": _vm.isLoading,
      "enableSearch": true,
      "searchQuery": _vm.searchQuery
    },
    on: {
      "search": function search($event) {
        return _vm.textSearch($event);
      }
    }
  }) : _c('NoDataMolecule', {
    attrs: {
      "to": _vm.to,
      "mode": 'add',
      "info": 'You have not selected any Assets yet. Click the Add Assets button.',
      "icon": 'asset',
      "buttonText": 'Add Assets',
      "title": 'Selected Assets'
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }