<template>
  <NoDataStyled>
    <HeaderMolecule :title="title" />
    <main>
      <div class="info">
        <GridIcon size="5x" v-if="icon === 'extension'" />
        <TruckIcon size="5x" v-else-if="icon === 'asset'" />
        <ToolIcon size="5x" v-else-if="icon === 'assetDimension'" />
        <span class="info-text"> {{ informationText }} </span>
      </div>
      <template v-if="buttonText">
        <router-link :to="to" v-if="to" tag="button" class="router" :disabled="disabled">
          <IconWrapper :class="{ disabled: disabled }">
            <PlusIcon size="1.5x" />
            <TextWrapper>{{ buttonText }} </TextWrapper>
          </IconWrapper>
        </router-link>
        <Button @click="$emit('action', buttonText)" v-else class="action-button" :disabled="disabled">
          <IconWrapper :class="{ disabled: disabled }">
            <PlusIcon />
            <TextWrapper>{{ buttonText }} </TextWrapper>
          </IconWrapper>
        </Button>
      </template>
    </main>
  </NoDataStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Button from '@/components/Atomic/Atoms/ButtonStyleless.vue'
import { PlusIcon, GridIcon, ToolIcon, TruckIcon } from 'vue-feather-icons'
import { flexCenter } from '@styles/mixins'
import HeaderMolecule from './HeaderMolecule.vue'
import chroma from 'chroma-js'

const NoDataStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3rem 1fr;
  padding: 0.5rem;
  > main {
    ${flexCenter};
    flex-direction: column;
    gap: 3rem;

    > .info {
      display: flex;
      gap: 3rem;
      flex-direction: column;
      ${flexCenter};
      > svg {
        color: ${({ theme }) => theme.colors.atomic.primary};
      }

      > .info-text {
        font-size: 18px;
      }
    }
    > .router {
      padding: 0;
      border-radius: 0.5rem;
      border: unset;
    }
  }
`
const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 35px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.atomic.primary};
  cursor: pointer;
  padding: 0 0.5rem;
  & > svg {
    color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.arrowWrapperBg : theme.colors.white)};
  }
  &.disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
    border-radius: 0.5rem;
    pointer-events: none;
  }
`
const TextWrapper = styled('div')`
  color:${({ theme }) => (theme.isDark ? theme.colors.atomic.arrowWrapperBg : theme.colors.white)};
  font-weight: 600;
  font-size: 14px;
  padding 0.2rem;
`
export default {
  props: {
    info: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    to: {
      type: Object,
    },
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledInfoText: {
      type: String,
      required: false,
    },
  },
  components: {
    Button,
    PlusIcon,
    NoDataStyled,
    IconWrapper,
    TextWrapper,
    GridIcon,
    ToolIcon,
    TruckIcon,
    HeaderMolecule,
  },
  computed: {
    informationText() {
      return this.disabledInfoText && this.disabled ? this.disabledInfoText : this.info
    },
  },
}
</script>
