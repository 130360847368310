var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CircleBadgeStyled', {
    attrs: {
      "marginLeft": _vm.marginLeft,
      "isSelected": _vm.isSelected,
      "size": _vm.size,
      "totalRowCount": _vm.totalRowCount
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.totalRowCount ? _c('span', [_vm._v(" /" + _vm._s(_vm.totalRowCount) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }