var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NoDataStyled', [_c('HeaderMolecule', {
    attrs: {
      "title": _vm.title
    }
  }), _c('main', [_c('div', {
    staticClass: "info"
  }, [_vm.icon === 'extension' ? _c('GridIcon', {
    attrs: {
      "size": "5x"
    }
  }) : _vm.icon === 'asset' ? _c('TruckIcon', {
    attrs: {
      "size": "5x"
    }
  }) : _vm.icon === 'assetDimension' ? _c('ToolIcon', {
    attrs: {
      "size": "5x"
    }
  }) : _vm._e(), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(" " + _vm._s(_vm.informationText) + " ")])], 1), _vm.buttonText ? [_vm.to ? _c('router-link', {
    staticClass: "router",
    attrs: {
      "to": _vm.to,
      "tag": "button",
      "disabled": _vm.disabled
    }
  }, [_c('IconWrapper', {
    class: {
      disabled: _vm.disabled
    }
  }, [_c('PlusIcon', {
    attrs: {
      "size": "1.5x"
    }
  }), _c('TextWrapper', [_vm._v(_vm._s(_vm.buttonText) + " ")])], 1)], 1) : _c('Button', {
    staticClass: "action-button",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('action', _vm.buttonText);
      }
    }
  }, [_c('IconWrapper', {
    class: {
      disabled: _vm.disabled
    }
  }, [_c('PlusIcon'), _c('TextWrapper', [_vm._v(_vm._s(_vm.buttonText) + " ")])], 1)], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }