<template>
  <GeneralInformationWrapperStyled>
    <HeaderMolecule
      :title="'General Information'"
      :buttonText="'Update Info'"
      @action="updateExtension($event)"
      :disabledAction="disableAction"
    />
    <main>
      <div class="row">
        <ValidatableFieldMolecule class="name" :validation="$v.name">
          <EditableFieldMolecule :label="$tc('name', 1)" :value.sync="$v.name.$model" :focus="true" />
        </ValidatableFieldMolecule>
      </div>
      <div class="row">
        <div class="description">
          <label>{{ $tc('description', 1) }}</label>
          <textarea v-model="description" />
        </div>
      </div>
    </main>
  </GeneralInformationWrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { FlashMessages } from '@common/singletons'
import { input } from '@styles/mixins'

import HeaderMolecule from './HeaderMolecule.vue'
import EditableFieldMolecule from '@/components/Atomic/Molecules/EditableFieldMolecule'
import ValidatableFieldMolecule from '@/components/Atomic/Molecules/ValidatableFieldMolecule'

import UPDATE_EXTENSION_MUTATION from '#/graphql/extensionBuilder/updateExtension.gql'

const GeneralInformationWrapperStyled = styled('div')`
  grid-area: information;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.5rem;
  grid-template-rows: 3rem 9.5rem;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border-radius: 0.5rem;
  gap: 0.5rem;
  > main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2rem 5rem;
    width: 75%;
    justify-self: center;
    gap: 1.5rem;
  }
  .row {
    display: flex;
    flex-direction: column;
    .name {
      width: 100%;
      > div {
        gap: 2rem;
      }
      .label {
        font-size: 14px;
        font-weight: 600;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      > label {
        white-space: nowrap;
        padding: 0.25rem 0rem;
        font-size: 14px;
        font-weight: 600;
      }
      > textarea {
        ${input}
        padding: 0.5rem;
        height: 3rem;
        resize: none;
        background: none;
        font-family: 'Open Sans', sans-serif;
        color: ${p => p.theme.colors.primary};
        border: 1px solid ${p => chroma(p.theme.colors.navFontNormal).alpha(0.8).css()};
        transition: border-color 0.5s ease;
        &:focus {
          border-color: ${p => p.theme.colors.primary};
        }
        width: 93%;
      }
    }

    .error {
      font-size: 12px;
      color: red;
    }
  }
`

export default {
  mixins: [validationMixin],
  components: {
    GeneralInformationWrapperStyled,
    HeaderMolecule,
    EditableFieldMolecule,
    ValidatableFieldMolecule,
  },
  validations: {
    name: {
      required,
    },
  },
  props: {
    myExtension: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: 'New Extension',
      description: '',
    }
  },
  watch: {
    myExtension: {
      handler(extension) {
        if (extension) {
          this.name = extension?.name ?? 'New Extension'
          this.description = extension?.description ?? ''
        }
      },
      immediate: true,
    },
  },
  computed: {
    disableAction() {
      const name = !this.$v.name.$dirty || this.myExtension?.name === this.name
      const description = this.myExtension?.description === this.description
      return Boolean((name && description) || this.$v.name.$invalid)
    },
  },
  methods: {
    async updateExtension() {
      try {
        const extension = await this.$apollo.mutate({
          mutation: UPDATE_EXTENSION_MUTATION,
          variables: {
            data: {
              name: this.name,
              description: this.description,
              assetIds: this.myExtension?.assetIds,
            },
            extensionId: this.$route.params.id,
          },
        })
        if (extension?.data?.updateExtension?.id) {
          FlashMessages.$emit('success', `General Information has been successfully Saved!`, {
            timeout: 3000,
          })
        }
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {},
}
</script>
