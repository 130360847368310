var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionsTableStyled', [_vm.isLoading ? _c('TalpaLoaderWrapper', {
    staticClass: "loader"
  }) : [_vm.assetDimensionDefinitions.length && _vm.assetIds.length ? _c('ExtensionTableMolecule', {
    attrs: {
      "columns": _vm.columns,
      "tableData": _vm.tableData,
      "title": 'Asset Dimensions',
      "buttonText": 'Add Asset Dimensions',
      "mode": 'add',
      "maxHeight": 700,
      "isLoading": _vm.isLoading,
      "sort-option": _vm.sortOption,
      "disabledAction": !_vm.assetIds.length,
      "expand-option": _vm.expandOption
    },
    on: {
      "action": function action($event) {
        return _vm.createAssetDimensionDefinition($event);
      }
    }
  }) : _c('NoDataMolecule', {
    attrs: {
      "info": 'You have not created Asset Dimensions yet. Click the Add Asset Dimensions button.',
      "icon": 'assetDimension',
      "buttonText": 'Add Asset Dimensions',
      "title": 'Asset Dimensions',
      "disabled": !_vm.assetIds.length,
      "disabledInfoText": 'Select Assets first to create Asset Dimensions!'
    },
    on: {
      "action": function action($event) {
        return _vm.createAssetDimensionDefinition($event);
      }
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }